import { css } from '@emotion/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { generalData } from '../../services/data';
import { ITheme } from '../../services/style/type';
import { secondarySpacePadding } from '../share';
type TCharacteresBio = {
  // text: readonly string[];
};

type TCardComponent = {
  props: {
    img: string;
    name: string;
    age: number;
    diagnosis: string;
    bio: string;
  };
};

const componentCSS = {
  self: (props: ITheme) =>
    css({
      backgroundColor: props.background.fourth,
    }),
  character: css({
    width: 312,
    height: 311,
    display: 'block',
  }),
  card: css({
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: 500,
    overflow: 'hidden',
    margin: 16,
    width: '100%',
    '@media (min-width: 600px)': {
      width: '32%',
    },
  }),
  cardImg: css({
    width: '100%',
    height: 180,
    objectFit: 'cover',
  }),

  cardContent: (props: ITheme) =>
    css({
      padding: '12px 16px',

      '& h2': {
        margin: 0,
        color: '#333',
        fontSize: 18,
      },
      '& p': {
        color: props.colorsText.fifth,
        textAlign: 'justify',
      },
    }),
};
const CharacteresBio: React.FC<TCharacteresBio> = () => {
  const { locale } = useRouter();
  return (
    <div
      css={[componentCSS.self, secondarySpacePadding]}
      className="flex_column characters_bio"
    >
      <h1 className={'font_size_30_bold'}>
        {generalData[locale].charactersBio.titles[0]}
      </h1>
      <p className="font_size_22_book">
        {generalData[locale].charactersBio.titles[1]}
      </p>
      <div className="flex_row_center_wrap">
        {generalData[locale].charactersBio.cards.map((character, index) => {
          return <CardComponent key={index} props={character} />;
        })}
      </div>
      <p style={{ fontWeight: 'bold' }} className="font_size_22_book">
        {generalData[locale].charactersBio.titles[2]}
      </p>
    </div>
  );
};
export default CharacteresBio;

const CardComponent: React.FC<TCardComponent> = ({ props }) => {
  return (
    <div css={componentCSS.card} className="character">
      <Image
        src={props.img}
        alt={props.name}
        css={componentCSS.cardImg}
        height={180}
        width={180}
        style={{ height: 'auto' }}
        priority
      />
      <div css={componentCSS.cardContent}>
        <h2 className="font_size_24_bold">
          {props.name}, {props.age}
        </h2>
        <p className="font_size_18_book">{props.diagnosis}</p>
        <p className="font_size_18_book">{props.bio}</p>
      </div>
    </div>
  );
};
