/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NextPage } from 'next';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useId } from 'react';
import Select from 'react-select';
import Cookies from '../components/Cookies';
import Banner from '../components/elements/Banner';
import CharacteresBio from '../components/elements/CharactersBio';
import IconsLogos from '../components/elements/IconsLogos';
import HomeFooter from '../components/footers/HomeFooter';
import MainFooter from '../components/footers/MainFooter';
import DefaultHeader from '../components/headers/DefaultHeader';
import Metas from '../components/headers/Metas';
import SubHeader from '../components/headers/SubHeader';
import FooterLayout from '../components/layouts/FooterLayout';
import MainLayout from '../components/layouts/mainLayout';
import { primarySpaceMargin } from '../components/share';
import { evaluationData } from '../features/evaluation';
import { generalData } from '../services/data';
import { optionsLanguages } from '../services/data/constant';
import { ITheme } from '../services/style/type';
import { customStylesSelection } from '../features/forms';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      '.entry_page_mobile': {
        height: '100vh',
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
        backgroundColor: 'white',
        overflow: 'hidden',
        top: 0,
        left: 0,
        '& img': {
          width: '212px',
          height: '46px',
        },
        '& button': {
          borderRadius: '20px',
          backgroundColor: props.background.secondary,
          padding: '1rem 4rem 1rem 4rem',
          fontWeight: 'bold',
          margin: 'auto',
        },
        '& p': {
          color: props.colorsText.primary,
          textAlign: 'center',
        },
        '@media (min-width: 400px)': {
          display: 'none',
        },
      },
      '.home_body': {
        color: props.colorsText.fourth,
        marginBottom: '2rem',
        height: '100%',
      },
    }),
  logo: css({
    marginTop: 20,
  }),
  imageWrapper: css({
    height: '35rem !important',
    width: '30rem !important',
    position: 'relative',
    '& img': {
      margin: 'auto',
    },
  }),
};

const EntryScreenOverLayMobile = () => {
  const { push, locale } = useRouter();
  const IDSelect = useId();
  return (
    <div className="entry_page_mobile flex_align_center_justify_center ">
      <div className="flex_column_center_around" style={{ height: '80vh' }}>
        <div css={componentCSS.logo}>
          <IconsLogos type={'addhereHorizontal'} />
        </div>
        <div css={componentCSS.imageWrapper}>
          <Image
            src={'/assets/png/celebrationKid.png'}
            alt={'celebration kid'}
            fill
            priority
            sizes="(min-width: 0px) 20rem, 15rem"
          />
        </div>
        <label htmlFor={IDSelect}>
          <Select
            styles={customStylesSelection}
            instanceId={IDSelect}
            aria-labelledby={IDSelect}
            placeholder={evaluationData[locale].selects.default}
            onChange={(choice) => push('/', '/', { locale: choice.value })}
            options={optionsLanguages}
          />
        </label>
        <HomeFooter />
      </div>
    </div>
  );
};

const Home: NextPage = () => {
  const { locale } = useRouter();
  const { status } = useSession();
  useEffect(() => {
    // Add the class to the body
    document.body.classList.add('force-overflow-auto');

    return () => {
      // Remove the class when the component is unmounted
      document.body.classList.remove('force-overflow-auto');
    };
  }, []);
  return (
    <>
      <Metas complement={generalData[locale].navigations[0]} />
      <div css={componentCSS.self} data-testid="test-home">
        {status !== 'authenticated' && <EntryScreenOverLayMobile />}
        <div className="home_body">
          <DefaultHeader />

          <MainLayout>
            <SubHeader />
            <div css={primarySpaceMargin}>
              <Banner
                text={generalData[locale].home.banner.text}
                buttons={generalData[locale].home.banner.buttons}
              />
            </div>
            <div css={primarySpaceMargin}>
              <CharacteresBio />
            </div>
          </MainLayout>
        </div>
      </div>
      <Cookies />
      <div
        css={{
          '@media (max-width: 400px)': {
            '& .footer_layout': {
              display: 'none !important',
            },
          },
        }}
      >
        <FooterLayout>
          <MainFooter />
        </FooterLayout>
      </div>
    </>
  );
};

export default Home;
