import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { routesIn } from '../../services/bridge';
import ButtonOriginal from '../buttons/ButtonOriginal';
import { css } from '@emotion/react';
import { generalData } from '../../services/data';

const componentCSS = {
  self: css`
    .button_original span {
      color: white;
    }
  `,
};
const HomeFooter: React.FC = () => {
  const { locale } = useRouter();

  return (
    <div css={componentCSS.self}>
      <Link href={routesIn.eng.agreement.link} locale={locale}>
        <ButtonOriginal text={generalData[locale].home.button.text} icon={''} />
      </Link>
    </div>
  );
};
export default HomeFooter;
