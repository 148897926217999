import { css } from '@emotion/react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { routesIn, routesOut } from '../../services/bridge';
import { ITheme } from '../../services/style/type';
import ButtonOriginal from '../buttons/ButtonOriginal';
import { secondarySpacePadding } from '../share';

type TBanner = {
  text: readonly string[];
  buttons: readonly { name: string; link: string }[];
};

const componentCSS = {
  self: (props: ITheme) =>
    css({
      backgroundColor: props.background.fourth,
    }),

  h3: css({
    color: '#503F95',
  }),
  button: css({
    color: '#503F95',
    gap: '10px',
    '& .first_button_': {
      '& span': {
        color: 'white',
      },
    },
    '& button': {
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      fontWeight: 'bold',
      fontSize: '1rem',
      backgroundColor: '#4f46e6',
      '&: hover': {
        cursor: 'pointer',
        backgroundColor: '#47ADCE !important',
        transition: 'backgroundColor 500ms',
        color: 'white',
      },
    },
    '& .last_button': {
      '& button': {
        backgroundColor: 'white !important ',
        color: '#4f46e6',
        '&: hover': {
          cursor: 'pointer',
          backgroundColor: '#47ADCE !important',
          transition: 'backgroundColor 500ms',
          color: 'white',
        },
      },
    },
  }),
};
const Banner: React.FC<TBanner> = ({ text, buttons }) => {
  const { locale } = useRouter();
  const { status } = useSession();

  return (
    <div css={[componentCSS.self, secondarySpacePadding]}>
      <h1 className={'font_size_30_bold'}>{text[0]}</h1>
      <h3 className="font_size_26_book" css={componentCSS.h3}>
        {text[1]}
      </h3>
      <div css={componentCSS.button} className="flex_row">
        <Link
          href={
            status === 'authenticated'
              ? routesIn.eng.students.link
              : routesIn.eng.agreement.link
          }
          locale={locale}
          aria-label="link to agreement"
        >
          <div className="first_button_">
            <ButtonOriginal text={buttons[0].name} icon={''} />
          </div>
        </Link>
        <a
          href={routesOut.moreInfo.link}
          target="_blank"
          rel="noreferrer"
          className="last_button font_size_22_book"
          aria-label="link to policy"
        >
          <ButtonOriginal text={buttons[1].name} icon={''} />
        </a>
      </div>
    </div>
  );
};
export default Banner;
