import { TDatabaseIndexDB, TStoresIndexDB } from './type';

const treeIndexDB: {
  version: number;
  cookies: {
    dataBase: TDatabaseIndexDB;
    cookiesStore: TStoresIndexDB;
  };
  email: {
    dataBase: TDatabaseIndexDB;
    emailStore: TStoresIndexDB;
  };
} = {
  version: 2,
  cookies: {
    dataBase: 'addhere',
    cookiesStore: 'cookies',
  },
  email: {
    dataBase: 'addhere',
    emailStore: 'email',
  },
};

export { treeIndexDB };
