import { css } from '@emotion/react';
import { ReactNode, useContext } from 'react';
import { ITheme } from '../../services/style/type';
import WebSiteContext from '../../services/contexts/WebSiteContext';

export interface IChildren {
  children: ReactNode;
}
const componentCSS = (webSiteTheme) => {
  return {
    self: (props: ITheme) =>
      css({
        position: 'fixed',
        bottom: '0%',
        zIndex: 3000,
        width: '100%',
        left: 0,
        backgroundColor: `${webSiteTheme.footerHeaderBackgroundColor ? webSiteTheme.footerHeaderBackgroundColor : props.colors.fifth_third} `,
      }),
  };
};
const FooterLayout = ({ children }: IChildren) => {
  const { webSiteTheme } = useContext(WebSiteContext);

  return (
    <main css={componentCSS(webSiteTheme).self} className="footer_layout">
      {children}
    </main>
  );
};
export default FooterLayout;
