import React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { administration } from '../../services/common/constant';
import { generalData } from '../../services/data';
type TMetas = { complement?: string };
const Metas: React.FC<TMetas> = ({ complement }) => {
  const { locale } = useRouter();
  const { headerSEO } = generalData[locale];
  const titl = headerSEO.title;
  const message = complement ? `${titl}-${complement}` : `${titl}`;

  return (
    <Head>
      <title>{message}</title>
      <meta charSet="utf-8" />
      <meta name={headerSEO.meta.name} content={headerSEO.meta.content} />
      <link rel="canonical" href={administration.website} />
      <link rel="icon" href={headerSEO.icon} />
      <meta name={headerSEO.meta2.name} content={headerSEO.meta2.content} />
      <link rel="manifest" href="../manifest.json" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `${headerSEO.dataStructureSeo}`,
        }}
      />
    </Head>
  );
};
export default Metas;
