import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { routesIn } from '../../services/bridge';
import { returnActualYear } from '../../services/common';
import { generalData } from '../../services/data';
import { ITheme } from '../../services/style/type';
import WebSiteContext from '../../services/contexts/WebSiteContext';

const componentCSS = {
  self: (props: ITheme) =>
    css({
      backgroundColor: props.background.primary,
      color: 'white',
      fontSize: '12px',
      padding: '.2rem',
      paddingLeft: '1rem',
      gap: '5px',
      '& .footer_policy_link': {
        textDecoration: 'underline',
        '&::hover': {
          cursor: 'pointer',
        },
      },
    }),
};
const MainFooter: React.FC = () => {
  const { locale } = useRouter();
  const { webSiteTheme } = useContext(WebSiteContext);
  return (
    <footer
      css={[
        componentCSS.self,
        { backgroundColor: webSiteTheme.footerHeaderBackgroundColor },
      ]}
      className="flex_align_center main_footer"
    >
      <p className="font_size_16_book" style={{ color: 'white' }}>
        {' '}
        ADDHERE © {returnActualYear()} – {generalData[locale].reserv} -
      </p>

      <a
        href={routesIn.eng.politicOfPrivacy[locale].link}
        target="_blank"
        className="footer_policy_link font_size_16_book"
        rel="noreferrer"
        aria-label="link to policy"
      >
        {generalData[locale].policy}
      </a>
    </footer>
  );
};
export default MainFooter;
