import { treeIndexDB } from './constant';
import {
  updateData,
  getAllKeys,
  getDataFromID,
  getAllDatas,
  updateDataIndexDB,
  initSchemaIndexDB,
} from './functions';
import {
  TDatabaseIndexDB,
  TObjectInStoresIndexDB,
  TStoresIndexDB,
} from './type';

export {
  treeIndexDB,
  updateData,
  getAllKeys,
  getDataFromID,
  getAllDatas,
  updateDataIndexDB,
  initSchemaIndexDB,
};
export type { TDatabaseIndexDB, TObjectInStoresIndexDB, TStoresIndexDB };
