import { deleteDB, openDB } from 'idb';
import { logMessage, logErrorAsyncMessage } from '../common';
import { treeIndexDB } from './constant';
import {
  TDatabaseIndexDB,
  TObjectInStoresIndexDB,
  TStoresIndexDB,
} from './type';

const initSchemaIndexDB = async (database: TDatabaseIndexDB, version: number) =>
  await openDB(database, version, {
    //reminder: the upgrade
    blocking: () => {
      // seems the user just opened this app again in a new tab
      // which happens to have gotten a version change
      // use react hot toast
      console.log('App is outdated, please close this tab');
    },
    blocked: () => {
      // seems an older version of this app is running in another tab
      // use react hot toast
      console.log('Please close this app opened in other browser tabs.');
    },
    //upgrade will get fired if blocking and blocked didn't trigger.
    upgrade: async (db, oldVersion) => {
      switch (oldVersion) {
        case 0:
          fromV0ToV1();
        //fallthrough
        case 1:
          fromV1ToV2();
        //fallthrough
        default:
          console.error('unknown db version');
      }
      function fromV0ToV1() {
        db.createObjectStore(treeIndexDB.cookies.cookiesStore, {
          autoIncrement: true,
        });
      }
      function fromV1ToV2() {
        db.createObjectStore(treeIndexDB.email.emailStore, {
          autoIncrement: true,
        });
        updateDataIndexDB(
          treeIndexDB.email.dataBase,
          treeIndexDB.version,
          treeIndexDB.email.emailStore,
          {
            date: new Date().toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
            authorisationEmailing: true,
          },
          treeIndexDB.version
        );
      }
    },
  });
const updateDataIndexDB = async (
  database: TDatabaseIndexDB,
  version: number,
  store: TStoresIndexDB,
  data: Record<string, unknown>,
  index: number
): Promise<void> => {
  const db = await openDB(database, version);
  try {
    db.put(store, data, index);
  } catch (error) {
    logMessage(`${logErrorAsyncMessage(
      'webworker/commun',
      'updateDataIndexDB'
    )},
			${error}`);
  } finally {
    db.close();
  }
};

const getAllDatas = async (
  database: string,
  version: number,
  store: string
) => {
  const db = await openDB(database, version);
  try {
    const datas = await db.getAll(store);
    return datas;
  } catch (error) {
    // Need to close the DB before deleting.
    db.close();
    // We delete the database if there is no store as it gets created automatically when we check in.
    await deleteDB('dashboard', {
      blocked() {
        // 	// …
      },
    });
    logMessage(`${logErrorAsyncMessage('webworker/commun', 'getAllData')},
			${error}`);
  }
};
const getDataFromID = async (
  database: TDatabaseIndexDB,
  version: number,
  store: TStoresIndexDB,
  ID: string | number
): Promise<TObjectInStoresIndexDB> => {
  try {
    const db = await openDB(database, version);
    const data = await db.get(store, ID);
    return data;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage('webworker/commun', 'getDataFromID')},
			${error}`);
  }
};
const getAllKeys = async (
  database: TDatabaseIndexDB,
  version: number,
  store: string
  // eslint-disable-next-line no-undef
): Promise<IDBValidKey[] | undefined> => {
  try {
    const db = await openDB(database, version);
    const allKeys = await db.getAllKeys(store);
    return allKeys;
  } catch (error) {
    logMessage(`${logErrorAsyncMessage('webworker/commun', 'getAllKey')},
			${error}`);
  }
};
const updateData = async (
  database: TDatabaseIndexDB,
  version: number,
  store: TStoresIndexDB,
  data: Record<string, unknown>
): Promise<void> => {
  const db = await openDB(database, version);
  try {
    // If a primary key like "idBook" has set when creating the store then, you just need to insert it in the data such {idBook: 1, name: 'newName'}
    // db.put(store, data); where data is:{idBook: 1, name: 'newName'}
    // If no primary key has been set, then you need to add the index after data
    db.put(store, data);
  } catch (error) {
    logMessage(`${logErrorAsyncMessage('webworker/commun', 'updateData')},
			${error}`);
  } finally {
    db.close();
  }
};

export {
  updateData,
  getAllKeys,
  getDataFromID,
  getAllDatas,
  updateDataIndexDB,
  initSchemaIndexDB,
};
