/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';
import { routesIn } from '../services/bridge';
import { generalData } from '../services/data';
import {
  getDataFromID,
  initSchemaIndexDB,
  updateDataIndexDB,
} from '../services/indexDB';
import { treeIndexDB } from '../services/indexDB/constant';
import { ITheme } from '../services/style/type';
import { fadeBootomToTop } from '../styles/';
import ButtonOriginal from './buttons/ButtonOriginal';

const componentCSS = {
  cookiesWrapper: (props: ITheme) =>
    css({
      position: 'absolute',
      backgroundColor: props.background.primary,
      width: '100%',
      bottom: '0rem',
      padding: '1rem',
      paddingTop: '2rem',
      letterSpacing: '.2rem',
      '@media (min-width: 550px)': {
        padding: '5rem',
      },
    }),
  paragraph: (props: ITheme) =>
    css({
      maxWidth: '900px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1.6rem',
      '& span': {
        color: 'white',
      },
      '& .cookies_link': {
        color: props.colorsText.fourth,
        textDecoration: 'underline',
      },
    }),
  buttons: (props: ITheme) =>
    css({
      '& button': {
        margin: '1rem',
        marginTop: '5rem',
        scale: '1',
        backgroundColor: props.background.fifth,
        '& span': {
          color: 'white',
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: props.colors.fifth_fourth,
        },
      },
    }),
};
const logicJS = () => {
  const [isButtonClicked, setIsButtonClicked] = React.useState(false);
  React.useEffect(() => {
    initSchemaIndexDB(treeIndexDB.cookies.dataBase, treeIndexDB.version);
    (async () => {
      const result = await getDataFromID(
        treeIndexDB.cookies.dataBase,
        treeIndexDB.version,
        treeIndexDB.cookies.cookiesStore,
        treeIndexDB.version,
      );
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      if (result && result.areCookiesAccepted) {
        setIsButtonClicked(true);
      }
    })();
    return () => {
      document.body.style.height = '';
      document.body.style.overflow = '';
    };
  }, []);
  const handleButtonClicked = (type: string) => {
    updateDataIndexDB(
      treeIndexDB.cookies.dataBase,
      treeIndexDB.version,
      treeIndexDB.cookies.cookiesStore,
      {
        date: new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        areCookiesAccepted: type === 'accept',
      },
      treeIndexDB.version,
    );

    document.body.style.height = '';
    document.body.style.overflow = '';
    setIsButtonClicked(!isButtonClicked);
  };
  return { isButtonClicked, handleButtonClicked };
};
const Cookies: React.FC = () => {
  const { isButtonClicked, handleButtonClicked } = logicJS();
  const { locale } = useRouter();

  return (
    <motion.div
      variants={fadeBootomToTop}
      initial={'closed'}
      animate={isButtonClicked ? 'closed' : 'open'}
      css={componentCSS.cookiesWrapper}
      className="flex_column_center_center"
      data-testid="cookies-id"
    >
      <div css={componentCSS.paragraph}>
        <span className="font_size_22_book">
          {generalData[locale].cookies.text[0]}&nbsp;
        </span>
        <a
          target="_blank"
          href={routesIn.eng.politicOfPrivacy[locale].link}
          rel="noopener noreferrer"
        >
          <span className="cookies_link font_size_22_book">
            {generalData[locale].cookies.text[1]}
          </span>
        </a>
      </div>
      <div className="flex_align_center" css={componentCSS.buttons}>
        <div
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleButtonClicked('accept');
          }}
          role="button"
          tabIndex={0}
          onClick={() => handleButtonClicked('accept')}
        >
          <ButtonOriginal
            text={generalData[locale].cookies.text[2]}
            icon={''}
          />
        </div>
        <div
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleButtonClicked('refuse');
          }}
          role="button"
          tabIndex={0}
          onClick={() => handleButtonClicked('refuse')}
        >
          <ButtonOriginal
            text={generalData[locale].cookies.text[3]}
            icon={''}
          />
        </div>
      </div>
    </motion.div>
  );
};
export default Cookies;
