const fadeBootomToTop = {
  open: {
    y: 0,
    display: 'block',
    transition: {
      delay: 1,
      height: { duration: 1 },
      default: { ease: 'linear' },
    },
  },
  closed: {
    y: '100%',
    display: 'none',
    transition: {
      delay: 0.2,
      y: { duration: 1 },
      default: { ease: 'linear' },
      display: { delay: 1.2 },
    },
  },
};

const opacityAnimation = {
  open: { opacity: 1, scale: 1 },
  closed: { opacity: 0, scale: 0 },
};

const growHeight = {
  open: {
    maxHeight: '100%',
    height: '100%',
    transition: {
      duration: 0.5,
    },
  },
  closed: {
    maxHeight: '0px',
    overflow: 'hidden',
    height: '0px',
    transition: {
      duration: 0.3,
    },
  },
};

const fadeLeftToRight = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 1, x: '-500%' },
};

export { fadeBootomToTop, opacityAnimation, growHeight, fadeLeftToRight };
